<template>
    <div>
        <div class="content-box">
            <div class="item">
                <img src="../../../assets/img/groupTools/group1.png" class="iconitem" @click="gotoTemplates" />
                <span>建群模板</span>
            </div>

        </div>
    </div>
</template>
<script>
import '../constant'
export default {
  name: '',
  components: {},
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
    gotoTemplates() {
      this.$router.push({ path: '/groupTemplates/' + global.APP_CONFIG.tenantId })
    }
  }
}

</script>
<style lang='less' scoped>
    .item{
        width:80px;float:left;margin-top:30px;margin-left:30px;text-align:center;
        img{width:80px;height:80px;margin:0px;}
        span{font-size:14px;color:#8a8a8a;margin:0px;}
    }
</style>
